import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { filterChildrenByKey } from '../../utils/HelpFunctions';
import { ButtonRow } from './Hero';
import { Text } from './Text';

// Critical CSS directly in a variable for fast inlining
const criticalCss = `
  .text-box-wrapper {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    margin-bottom: 3rem;
  }

  .content-wrapper {
    width: 100%;
    padding: 40px;
  }

  .html-wrapper {
    width: 50%;
    margin: 25px auto;
    font-family: ${theme.fonts.secondary};
  }
`;

const TextBoxWrapper = styled('div')`
  width: 100%;
  max-width: 80rem;
  margin: auto;
  margin-bottom: 3rem;

  padding: 0 ${props => props.boxpadding}px;

  &.fullWidth {
    max-width: 100%;
  }

  ${theme.below.lg} {
    margin-bottom: 2.4rem;
  }
`;

const ContentWrapper = styled('div')`
  width: 100%;
  padding: 40px;

  ${theme.below.lg} {
    padding: 20px;

    h1 {
      font-size: 22px;
      font-family: ${theme.fonts.secondary};
    }
  }

  .button-component {
    margin-top: 20px;
    cursor: pointer;
  }
`;

const HtmlWrapper = styled('div')`
  width: 50%;
  margin: 25px auto;
  font-family: ${theme.fonts.secondary};

  p {
    color: white;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.04em;
  }
  a {
    color: white;
  }

  ${theme.below.lg} {
    width: 100%;
    p {
      font-size: 14px;
    }
  }
`;

const UnMemoTextBox = ({
  children,
  backgroundColor,
  title,
  fullWidth,
  padding,
  html
}) => {
  const buttonChildren = React.useMemo(
    () => filterChildrenByKey(children, 'button', true),
    [children]
  );
  const basicButtonChildren = React.useMemo(
    () => filterChildrenByKey(children, 'basicButton', true),
    [children]
  );

  return (
    <>
      <Helmet>
        <style>{criticalCss}</style>
      </Helmet>
      <TextBoxWrapper
        className={cx(fullWidth?.value && 'fullWidth')}
        boxpadding={padding?.value ?? 0}
      >
        <ContentWrapper style={{ background: backgroundColor?.value }}>
          {title?.value && <Text shadow={true} size={'TITLE'} text={title} />}
          {html?.value && (
            <HtmlWrapper
              dangerouslySetInnerHTML={{
                __html: html.value
              }}
            />
          )}
          <ButtonRow>
            {buttonChildren}
            {basicButtonChildren}
          </ButtonRow>
        </ContentWrapper>
      </TextBoxWrapper>
    </>
  );
};

const TextBox = memo(UnMemoTextBox);
export default TextBox;
