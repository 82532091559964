import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import { css, cx } from 'linaria';
import { styled } from 'linaria/react';
import React, { useContext } from 'react';
import { SliderContext } from '../Layout/SharedSlider';
import { Favourite } from '../ProductList/Favourite';
import { StyledBadges } from '../ProductPage/Images/ImageContainer';
import { theme } from '../Theme';
import { PreStyledPrice } from '../ui/PreStyledPrice';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
    border: none;
    position: relative;
  }

  .product-card-detail {
    background: white;
    padding: 0.75em 0;
    line-height: 1.35;
    border-top: none;

    .main-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .sub-name {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #838383;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;

    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }
  }
  [data-flight-image-children] {
    position: absolute !important;
  }
`;

const MainRowWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    display: flex;
  }
`;

const Badge = styled('div')`
  min-width: 50px;
  text-align: right;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  color: ${theme.colors.gold};
  margin-right: 4px;

  &.left {
    text-align: left;
    margin-right: 0;
    margin-left: 4px;
  }
`;

export const StyledPrice = styled(PreStyledPrice)`
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;

  &.normal-price {
    color: #333;
  }

  &.old-price {
    text-decoration: line-through;
    text-decoration-color: #848484;
    color: #848484;
    margin-right: 6px;
  }

  &.new-price {
    div {
      font-weight: 700;
      color: ${theme.colors.gold} !important;
    }
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'li',
  children,
  ...linkProps
}) {
  const hasImages = product.images && product.images.length > 0;
  const Tag = as;
  let badges = [...product.badges];
  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const sliderContext = useContext(SliderContext);
  const brandName = product?.customFields?.find(({ key }) => key === 'merke')
    ?.stringValue;

  const giftCardProducts = ['9633710', '9633713', '9633717'];
  const isGiftCard = giftCardProducts.includes(product?.articleNumber);

  //Calculations for Newsbagdge & Discountbadge
  const today = Date.now();
  const productDate = Date.parse(product?.stockStatus?.stockDate.split('T')[0]);
  const daysTotal = today - productDate;
  const newsBadge = Math.ceil(daysTotal / (1000 * 3600 * 24)) < 15;
  const discountPrice =
    product?.previousPrice?.incVat !== product?.price?.incVat;
  const priceBadge =
    100 - (product.price?.incVat / product?.previousPrice?.incVat) * 100;

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        style={{
          margin: '0 10px 1em'
        }}
        onClick={e => {
          // prevent click when dragging in slider
          if (sliderContext?.dragging) {
            e.preventDefault();
          }
        }}
      >
        {hasImages ? (
          <Image
            cover
            sizes={imageSizes}
            aspect={imageAspect}
            alt={product.images[0].alt}
            src={product.images[0].url}
          >
            <StyledBadges badges={badges} />
            {newsBadge && (
              <Badge>
                <p>Nyhet</p>
              </Badge>
            )}
            {discountPrice && (
              <Badge className="left">
                <p>{priceBadge + '%'}</p>
              </Badge>
            )}
          </Image>
        ) : (
          <Image src={transparentDataImg} />
        )}
        <section className="product-card-detail">
          <header>
            <MainRowWrapper>
              {product?.subName ? (
                <h3 className="main-name">{product.subName || '\u00A0'}</h3>
              ) : brandName ? (
                <h3 className="main-name">{brandName}</h3>
              ) : (
                ''
              )}
              <Favourite product={product}></Favourite>
            </MainRowWrapper>
            <h4 className="sub-name">{product?.name}</h4>
          </header>
          {!isGiftCard && (
            <div className="price-package-wrapper">
              {product?.previousPrice?.incVat !== product?.price?.incVat ? (
                <>
                  <StyledPrice
                    price={product.previousPrice}
                    className="old-price"
                  />
                  <StyledPrice price={product.price} className="new-price" />
                </>
              ) : (
                <StyledPrice price={product.price} className="normal-price" />
              )}
            </div>
          )}
        </section>
      </ProductLink>
      {children}
    </Tag>
  );
}
