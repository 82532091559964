import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { waitForFindify } from '@findify/react-bundle';
import { useLocation, useHistory } from 'react-router';

// LOADER FOR THE DIFFERENT CHANNELS
export const FindifyLoader = () => {
  const selectedChannel = useContext(ChannelContext)?.selectedChannel;
  const environment = selectedChannel?.url.match(/test/)
    ? 'test'
    : 'production';

  const findifyAccounts = {
    //Change to prod when going live.
    production: {
      // channel_1: 'fernerjacobsen-staging.no' // NO
      channel_1: 'fernerjacobsen.no' // NO
    },
    test: {
      // channel_1: 'fernerjacobsen-staging.no' // NO
      channel_1: 'fernerjacobsen-staging.no' // NO
    }
  };
  const channelId = selectedChannel.id;
  const accountId = findifyAccounts[environment]['channel_' + channelId];

  // console.log('environment', environment);
  // console.log('channelId', channelId);

  return selectedChannel ? (
    <Helmet>
      <script
        id="findify-script"
        src={`https://assets.findify.io/${accountId}.min.js`}
        async="true"
        defer="true"
        type="text/javascript"
      />
    </Helmet>
  ) : null;
};

// FINDIFY PAGE VIEW TRACKING
export const FindifyTracker = ({ isProdPage, product, selectedVariation }) => {
  const findifyTrackingContext = useContext(FindifyTrackingContext);
  const location = useLocation();
  const { pathname } = location;

  const triggerFindifyTracking = async () => {
    const { analytics } = await waitForFindify();

    // REGULAR PAGES, JUST SEND VIEW PAGE EVENT
    if (!isProdPage) {
      analytics.sendEvent('view-page');
    }
    // PRODUCT PAGE - SEND MORE
    else {
      const baseItemId = product.articleNumber;

      // USE FIRST VARIANT IF NO SELECTED VARIANT
      // USE REGULAR ART NO AS VARIANT ID FOR PRODS WITHOUT VARIANTS
      let findifyVariantId = selectedVariation
        ? selectedVariation.articleNumber
        : null;
      if (!findifyVariantId) {
        if (product.variants?.values.length) {
          findifyVariantId = product.variants.values[0].articleNumber;
        } else {
          findifyVariantId = product.articleNumber;
        }
      }
      const trackingObject = {
        item_id: baseItemId,
        variant_item_id: findifyVariantId,
        force: true
      };

      analytics.sendEvent('view-page', trackingObject);
    }
  };

  useEffect(() => {
    if (pathname !== findifyTrackingContext.lastTrackedPath) {
      if (!isProdPage || product) {
        // STORE TRACKED PATHNAME
        findifyTrackingContext.setLastTrackedPath(pathname);

        // TRIGGER TRACKING
        triggerFindifyTracking();
      }
    }
  });
  return null;
};

// FINDIFY CART TRACKER, CLUNKY SETUP B/C CART ITEM STATE
// JUMPS AROUND BEFORE SETTLING ON NEW VALUE
let cartItemQtyCounter = 0;
let timeoutVar = null;
export const FindifyCartTracker = ({ cartData }) => {
  clearTimeout(timeoutVar);
  timeoutVar = setTimeout(function() {
    if (cartData && cartData.totalQuantity !== cartItemQtyCounter) {
      cartItemQtyCounter = cartData.totalQuantity;
      triggerFindifyCartTracking(cartData.items);
    }
  }, 1000);
  return null;
};

const triggerFindifyCartTracking = async cartItems => {
  let lineItems = [];
  cartItems.forEach(item => {
    const baseItemId = item.articleNumber;

    lineItems.push({
      item_id: baseItemId,
      quantity: item.quantity,
      unit_price: item.product.price.incVat,
      variant_item_id: item.articleNumber
    });
  });

  const { analytics } = await waitForFindify();
  analytics.sendEvent('update-cart', {
    line_items: lineItems
  });
};

// TRACKING CONTEXT - FOR AVOIDING MULTIPLE IDENTICAL VIEW PAGE EVENTS BEING SENT
const FindifyTrackingContext = React.createContext(null);

const FindifyProvider = ({ children }) => {
  const [lastTrackedPath, setLastTrackedPath] = useState('');
  const history = useHistory();

  const goToRoute = route => {
    history.push(route);
  };

  useEffect(() => {
    window.goToRoute = goToRoute;
  });

  return (
    <FindifyTrackingContext.Provider
      value={{ lastTrackedPath, setLastTrackedPath }}
    >
      <FindifyLoader />
      {children}
    </FindifyTrackingContext.Provider>
  );
};

export default FindifyProvider;
