import { useQuery } from '@apollo/react-hooks';
import { styled } from 'linaria/react';
import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import { ProductCard } from '../CategoryPage/ProductCard';
import MaxWidth from '../Layout/MaxWidth';
import { SliderWithButtons } from '../ui/SharedSlider';
import multipleCategoryRowQuery from './MultipleCategoryRowQuery.gql';
import { RowTitle } from './RowTitle';
import { useInView } from 'react-intersection-observer';

const MultipleCategoryRowWrapper = styled(MaxWidth)`
  padding: 0;
`;

const CategoryNavigator = styled('div')`
  margin-top: 14px;
  display: flex;
  justify-content: center;
  margin-bottom: 1.7rem;
`;

const CategoryItemButton = styled('div')`
  padding: 0 1rem;
  position: relative;
  cursor: pointer;

  &.active {
    p {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2rem);
        height: 1px;
        background: #333333;
      }
    }
  }
`;

const ProductSliderWrapper = styled('div')``;

const calculatedActivePath = (children, activeCategory) => {
  return children[activeCategory]?.props?.category?.value?.primaryRoute?.path;
};

export const MultipleCategoryRow = ({ title, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  const [activeCategory, setActiveCategory] = useState(0);
  let categoryResult = useQuery(multipleCategoryRowQuery, {
    variables: {
      pathname: calculatedActivePath(children, activeCategory)
    },
    errorPolicy: 'ignore'
  });

  if (!calculatedActivePath(children, activeCategory)) {
    return null;
  }

  const deskstopSlides =
    categoryResult?.data?.products < 5 ? categoryResult?.data?.products : 5;
  const mobileSlides =
    categoryResult?.data?.products < 2 ? categoryResult?.data?.products : 2;

  return (
    <MultipleCategoryRowWrapper ref={ref}>
      <RowTitle title={title} />
      <CategoryNavigator>
        {children.map((child, i) => (
          <CategoryItemButton
            key={i}
            className={activeCategory === i ? 'active' : null}
            onClick={() => {
              setActiveCategory(i);
            }}
          >
            {child}
          </CategoryItemButton>
        ))}
      </CategoryNavigator>
      <ProductSliderWrapper>
        <SliderWithButtons
          desktopSlides={deskstopSlides}
          mobileSlides={mobileSlides}
          arrowTopPosition={'38%'}
        >
          {categoryResult?.data?.route?.object?.products?.result?.map(
            product => (
              <ProductCard
                key={product.articleNumber}
                product={product}
                as="div"
              />
            )
          )}
        </SliderWithButtons>
      </ProductSliderWrapper>
    </MultipleCategoryRowWrapper>
  );
};

const CategoryName = styled('p')`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
`;

export const MultipleCategoryRowItem = ({ name }) => {
  return <CategoryName>{name?.value}</CategoryName>;
};
