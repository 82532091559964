import React, { useState, useCallback } from 'react';
import loadable from '@loadable/component';
import MaxWidth from '../Layout/MaxWidth';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { useInView } from 'react-intersection-observer';

// Load Flowbox dynamically using loadable
const Flowbox = loadable(() => import('../Flowbox/FlowboxSimple'), {
  fallback: <div>Loading Flowbox...</div> // Optional: Fallback while loading
});

const ContentRow = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ContentWrapper = styled('div')`
  justify-self: center;
  max-width: 350px;
  color: #000;
  h2 {
    font-family: ${theme.fonts.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  p,
  a {
    font-family: ${theme.fonts.primary};
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
  }

  a {
    font-weight: 600;
    color: #000;
    &:visited {
      color: black;
    }
  }
  > * {
    margin-bottom: 10px;
  }

  margin-bottom: 1.5rem;
`;

const FlowBoxContainer = styled('div')`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    transform: rotate(45deg);
    color: grey;
  }
`;

export const FlowboxComponent = ({
  flowboxKey,
  title,
  text,
  tags,
  product
}) => {
  const sortedTags = tags?.value?.split(',');
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the observer triggers only once
    threshold: 0.1 // Load the component when 10% of it is visible
  });

  // Callback to set the showed state only when it becomes in view
  const handleInView = useCallback(() => {
    if (inView && !showed) setShowed(true);
  }, [inView, showed]);

  handleInView(); // Call the callback function

  return (
    <MaxWidth ref={ref}>
      {showed && (
        <>
          <ContentRow>
            <ContentWrapper>
              {title?.value && <h2>{title?.value}</h2>}
              {text?.value && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: text?.value
                  }}
                />
              )}
            </ContentWrapper>
          </ContentRow>
          <FlowBoxContainer>
            <Flowbox
              menuTags={sortedTags}
              feed={flowboxKey?.value}
              productId={product}
            />
          </FlowBoxContainer>
        </>
      )}
    </MaxWidth>
  );
};
