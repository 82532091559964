import React, { useContext } from 'react';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import { SearchBar } from './SearchBarFindify';
import SearchButton from './SearchButton';
import FavouriteButton from '../../ProductList/FavouriteCount';
import CartButton from '../../Cart/CartButton';
import { ReactComponent as Stores } from '../../../svg/MenuStores.svg';
import { ReactComponent as Person } from '../../../svg/MenuProfile.svg';
import { ReactComponent as Bonusprogram } from '../../../svg/Bonusprogram.svg';

import ModalContext from '@jetshop/ui/Modal/ModalContext';

const SearchFieldWrapper = styled('div')`
  display: flex;
  svg {
    margin-bottom: 3px !important;
  }
  &.open {
    align-self: center;
  }
  &.closed {
    flex: 1;
  }
`;

const CartWrapper = styled('div')`
  ${theme.below.lg} {
    .badge {
      right: -5px;
      width: 16px;
      height: 16px;
      font-size: 10px;
    }
  }
`;

const FavWrapper = styled('div')`
  font-size: 0.75rem;
  .header-button {
    margin: 0;
    padding: 0;
  }
  ${theme.below.lg} {
    .badge {
      right: -5px;
      width: 16px;
      height: 16px;
      font-size: 10px;
    }
  }
`;

const StoresLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 18px;
  }
`;

const MyPagesLink = styled(Link)`
  display: flex;
  align-items: center;
  svg {
    margin: 4px;
    margin-bottom: 0;
    height: 18px;
  }
`;

const Column = styled('div')`
  display: flex;
  justify-content: space-between;
  flex: 1;

  &.right {
    > div {
      display: flex;
      height: 100%;
      align-items: flex-end;
      flex: 1;
      padding-right: 1rem;
      margin-top: 6px;
      justify-content: flex-end;
      > * {
        margin-left: 1rem;
      }
    }
    .header-search {
      margin-bottom: 0;
    }
  }
  &.left {
    a {
      padding: 25px 10px;
      @media (max-width: 1250px) {
        padding: 25px 5px;
      }
    }
  }

  ${theme.below.lg} {
    display: flex;
    flex: 1;
    svg {
      height: 20px;
    }

    &.right {
      svg {
        height: 20px;
        width: 20px;
      }
      padding-right: 0;
      button,
      > * {
        margin-left: 0.2rem !important;
      }
    }

    &.left {
      justify-content: flex-start;
      svg {
        height: 20px;
        width: 20px;
      }
      .header-button {
        svg {
          height: 23px;
          width: 23px;
          margin-right: 5px;
        }
      }
    }

    .header-search {
      :hover,
      :focus {
        outline: none;
      }
      svg {
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
`;

const BonusLink = styled(Link)``;

export const IconRow = ({ searchOpen, setSearchOpen, loggedIn }) => {
  const { routes } = useShopConfig();
  const { modals } = useContext(ModalContext);

  return (
    <Column className="right desktopmenu">
      <div>
        <SearchFieldWrapper className={searchOpen ? 'open' : ''}>
          <SearchButton
            className="header-search"
            onClick={() => setSearchOpen(!searchOpen)}
            searchOpen={searchOpen}
          />
          {searchOpen && (
            <SearchBar searchOpen={true} setSearchOpen={setSearchOpen} />
          )}
        </SearchFieldWrapper>
        <StoresLink className="header-button" to={routes.stores.path}>
          <Stores />
          <span>{t('Find Store')}</span>
        </StoresLink>
        <BonusLink className="header-button" to="/inspirasjon-og-rad-1/bonus-1">
          <Bonusprogram />
          <span>{t('Bonusprogram')}</span>
        </BonusLink>

        <MyPagesLink className="header-button" to={routes.myPages.path}>
          <Person />
          <span>{loggedIn ? t('My Pages') : t('Log in')}</span>
        </MyPagesLink>
        <FavWrapper>
          <FavouriteButton modals={modals} className="header-button" />
        </FavWrapper>
        <CartWrapper>
          <CartButton modals={modals} className="header-button" />
        </CartWrapper>
      </div>
    </Column>
  );
};
