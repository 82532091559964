import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import { BoxContent } from './BoxContent';
import { LinkEvaluator } from '../../utils/LinkEvaluator';

const ImageBoxRowWrapper = styled('div')`
  padding: 0;
  width: 100%;
  max-width: 86rem;
  margin: auto;
  display: flex;
  padding: 0 ${props => props.imageboxpadding}px;

  ${theme.below.lg} {
    height: 100%;
    max-width: 90%;
    padding: 0;
    flex-direction: column-reverse;
    br {
      display: none;
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    ${theme.below.lg} {
      flex-direction: column-reverse;
    }
  }
  &.fullWidth {
    max-width: 100%;
  }

  .image-wrapper {
    --ratio: ${props => props.ratio};
    max-width: var(--ratio) !important;
    ${theme.below.lg} {
      min-width: 100%;
    }
  }

  .box-wrapper {
    --textratio: ${props => props.textratio};
    max-width: var(--textratio) !important;
    ${theme.below.lg} {
      max-width: 100% !important;
      width: 100%;
    }
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    margin: 10px 0;
    display: flex;
    justify-content: center;

    ${theme.below.lg} {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }

    li {
      text-align: left !important;
      list-style: disc;

      ${theme.below.lg} {
        text-align: left !important;
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: 100%;
  /* min-width: 50%; */
`;
const BoxWrapper = styled('div')`
  position: relative;
  width: 100%;
  background: ${props => props.backgroundcolor};
  ${theme.below.lg} {
    .under {
      margin-bottom: 20px;
    }
  }
`;

export const ImageBox = ({
  children,
  padding,
  fullWidth,
  reverse,
  ratio,
  rest
}) => {
  return (
    <ImageBoxRowWrapper
      className={cx(
        fullWidth?.value && 'fullWidth',
        reverse?.value && 'reverse'
      )}
      imageboxpadding={padding?.value ?? 0}
      ratio={ratio?.value + '%'}
      textratio={100 - ratio?.value + '%'}
    >
      {children}
    </ImageBoxRowWrapper>
  );
};

export const ImageBoxItem = ({
  background,
  desktopImage,
  mobileImage,
  desktopImageAspect,
  mobileImageAspect,
  verticalPosition,
  horizontalPosition,
  textColor,
  title,
  boldTitle,
  h1Title,
  link,
  text
}) => {
  const imageSizes = [1 / 4, 1 / 2, 1, 1, 1];

  return (
    <>
      <ImageWrapper className="image-wrapper">
        <LinkEvaluator link={link?.value}>
          <Above breakpoint="lg">
            {matches => (
              <Image
                sizes={imageSizes}
                src={matches ? desktopImage?.value : mobileImage?.value}
                aspect={
                  matches ? desktopImageAspect?.value : mobileImageAspect?.value
                }
                cover
              />
            )}
          </Above>
        </LinkEvaluator>
      </ImageWrapper>
      <BoxWrapper
        color={textColor?.value}
        backgroundcolor={background?.value}
        className="box-wrapper"
      >
        <Above breakpoint="lg">
          {matches => (
            <BoxContent
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              color={textColor}
              title={title}
              boldTitle={boldTitle}
              h1Title={h1Title}
              text={text}
              // buttonText={buttonText}
              // buttonTheme={buttonTheme}
              // link={link}
              textPosition={matches ? null : { value: 'under' }}
            />
          )}
        </Above>
      </BoxWrapper>
    </>
  );
};
