import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/Image';

// Wrapper for the BoxPush component
const BoxPushWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap to new lines if they don't fit */

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

// Wrapper for individual BoxPushItem
const BoxPushItemWrapper = styled.div`
  width: 100%;
  margin-right: 10px;
  max-width: 25%; /* Equivalent to calc(100% / 4) */
  position: relative; /* Ensure child positioning is relative to this wrapper */

  ${theme.below.lg} {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  [data-flight-image-children] {
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;

    ${theme.below.lg} {
      padding: 0;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  p {
    font-size: 18px;
    font-weight: 500;
  }

  .title {
    font-size: 24px;
  }
`;

export const BoxPush = ({ children }) => (
  <BoxPushWrapper>{children}</BoxPushWrapper>
);

export const BoxPushItem = ({ title, imageUrl, boxLink, textColor, text }) => (
  <BoxPushItemWrapper>
    <a
      href={boxLink?.value}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: textColor?.value }}
    >
      <Image
        src={imageUrl?.value}
        aspect="400:400"
        cover={true}
        crop={true}
        loading="lazy" // Lazy loading for performance
        alt={title?.value || 'Image'}
      >
        {text?.value && <p>{text.value}</p>}
        {title?.value && <h3 className="title">{title.value}</h3>}
      </Image>
    </a>
  </BoxPushItemWrapper>
);
