import React from 'react';
import { styled } from 'linaria/react';
import { ContentPlacer } from './ContentPlacer';
import { cx, css } from 'linaria';
import { theme } from '../Theme';
import { Link } from 'react-router-dom';

const ImageHeroContentWrapper = styled('div')`
  color: ${props => props.color};
  ${theme.above.lg} {
    padding: 30px;
  }

  a {
    text-decoration: none;
  }

  button {
    width: auto;
  }

  h1,
  h2 {
    font-size: 40px;

    ${theme.below.lg} {
      font-size: 24px;
    }
  }
`;

const heroButtonStyle = css`
  margin-top: 30px;
  padding: 0 30px;
`;

const boxButtonStyle = css`
  margin-top: 23px;
  ${theme.below.lg} {
    margin-top: 2rem;
  }
`;

export const BoxContent = ({
  padding,
  verticalPosition,
  horizontalPosition,
  color,
  h1Title,
  boldTitle,
  title,
  text,
  buttonText,
  buttonTheme,
  handleDummyButtonClick,
  link,
  hasImage,
  forType,
  textPosition
}) => {
  return (
    <ContentPlacer
      padding={padding}
      textPosition={textPosition}
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
      relative={hasImage ? false : true}
    >
      <ImageHeroContentWrapper
        color={color?.value ?? '#fff'}
        className="image-hero-content"
      >
        <div className="titles-wrapper">
          {title?.value && h1Title?.value ? (
            <h1
              style={
                boldTitle?.value === true
                  ? { fontWeight: '500' }
                  : { fontWeight: '400' }
              }
              className="title"
              dangerouslySetInnerHTML={{
                __html: title?.value
              }}
            />
          ) : (
            <h2
              style={
                boldTitle?.value === true
                  ? { fontWeight: '500' }
                  : { fontWeight: '400' }
              }
              className="title"
              dangerouslySetInnerHTML={{
                __html: title?.value
              }}
            />
          )}
        </div>
        {text?.value && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )}
        {buttonText?.value &&
          (link?.value ? (
            <Link to={link?.value}>
              <button
                className={cx(
                  buttonTheme?.value,
                  forType === 'hero' ? heroButtonStyle : boxButtonStyle
                )}
                clickcallback={handleDummyButtonClick}
              >
                {buttonText?.value}
              </button>
            </Link>
          ) : (
            <button
              className={cx(
                buttonTheme?.value,
                forType === 'hero' ? heroButtonStyle : boxButtonStyle
              )}
              clickcallback={handleDummyButtonClick}
            >
              {buttonText?.value}
            </button>
          ))}
      </ImageHeroContentWrapper>
    </ContentPlacer>
  );
};
