import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import { ReactComponent as Carrot } from '../../../../svg/Caret.svg';
import { Link } from 'react-router-dom';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  position: relative;
  height: 100%;
  ul,
  li,
  a {
    height: 100%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  position: relative;
  a,
  li {
    padding: 0 0.5rem;
    display: inline-block;
    color: #5c5c5c;
    text-decoration: none;

    &.active {
      color: #000;
      font-weight: bold;
    }

    &:hover {
      text-decoration: none;
      color: #b99864 !important;
      &:after {
        height: 2px;
        width: 80%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: ${theme.colors.gold};
        content: '';
      }
    }
  }

  svg {
    width: 10px;
    height: 5px;
    margin-left: 7px;
    margin-bottom: 3px;
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
            <MainMenuItem>
              <Link to="/gavekort">Gavekort</Link>
            </MainMenuItem>
            {/* <MainMenuItem>
              <Link to="/barbour-kampanje">Barbour Kampanje</Link>
            </MainMenuItem> */}
          </ul>

          <SubMenuWrapper
            data={queryData}
            pose={props.activeCategories.length > 0 ? 'open' : 'closed'}
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => props.setActiveCategory(cat)}
    >
      {cat?.id !== 867 ? (
        <CategoryLink
          // When following a category link, close the menu
          onClick={props.clearActiveCategories}
          category={cat}
        >
          {cat.name}
          <Carrot />
        </CategoryLink>
      ) : (
        <Link onClick={props.clearActiveCategories} to="/inspirasjon-og-raad">
          {cat.name}
        </Link>
      )}
    </MainMenuItem>
  );
};

export default CategoryMenuContainer;
