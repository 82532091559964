import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PagePreviewQuery from '../PagePreviewQuery.gql';
import { JournalPostCard } from '../ContentPage/JournalPostCard';

export const ArticleItem = ({ id }) => {
  const { data } = useQuery(PagePreviewQuery, {
    variables: {
      id: id?.value
    },
    errorPolicy: 'ignore'
  });

  return <JournalPostCard post={data?.page} menu={true} postslength={1} />;
};
