import Badges from '@jetshop/ui/ProductList/Badges';
import React, { useState } from 'react';
import { css, cx } from 'linaria';
import { useSwitchToVariantImage } from '@jetshop/core/hooks/Product';
import { theme } from '../../Theme';
import loadable from '@loadable/component';
import FsLightbox from 'fslightbox-react';

const LoadableGallery = loadable(() => import('@jetshop/ui/Gallery/Gallery'), {
  fallback: <div></div>
});

const wrapper = css`
  display: flex;
  background: #f3f3f3;
  flex-direction: column;
  position: relative;

  .fullwidth-wrapper {
    height: 390px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
  }

  ${theme.below.lg} {
    .image-gallery-right-nav,
    .image-gallery-left-nav,
    .image-gallery-fullscreen-button {
      display: none !important;
    }
  }

  .image-gallery-thumbnails {
    justify-content: flex-start !important;
    padding-top: 2rem !important;
  }

  .image-gallery-thumbnails-container {
    display: flex;
    flex-direction: column;
    ${theme.below.lg} {
      flex-direction: row;
    }
  }
  .image-gallery-thumbnails {
    ${theme.below.lg} {
      padding: 0;
    }
  }
  .image-gallery-thumbnail + .image-gallery-thumbnail {
    margin: 0;
    margin-left: -1px;
  }

  .image-gallery-thumbnail {
    transition: none;
    position: relative;
    border: none;

    ${theme.below.lg} {
      width: 100%;
      .image-gallery-thumbnails-container {
        text-align: left;
      }
      margin-bottom: 0;
      &.active {
        border: none;
        :after {
          content: '';
          display: block;
          width: 100%;
          height: 4px;
          background: ${theme.colors.gold};
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  a {
    margin-bottom: 5px;
    width: 75px;
  }

  .image-gallery-content
    .image-gallery-slide-wrapper
    .image-gallery-swipe
    .image-gallery-slides
    .image-gallery-slide
    .image-gallery-image
    > div:nth-child(2) {
    margin: 0.8rem;
  }
`;

const badgesStyle = css`
  display: contents;

  > div > div > div {
    padding: 0.2rem 1rem;
    line-height: initial;
    font-weight: bold;
    font-size: 12px;
  }

  .black {
    background: black;
    color: white;

    &.outline {
      background: transparent;
      border: 1px solid black;
      color: black;
    }
  }

  .gold {
    background: ${theme.colors.gold};
    color: white;
    &.outline {
      background: transparent;
      border: 1px solid ${theme.colors.gold};
      color: ${theme.colors.gold};
    }
  }

  .white {
    background: white;
    color: black;
    &.outline {
      background: transparent;
      border: 1px solid white;
      color: white;
    }
  }
`;

export const StyledBadges = props => (
  <div className={badgesStyle}>
    <Badges {...props} />
  </div>
);

export default function ImageContainer({ images, badges, variant, ...rest }) {
  const galleryRef = React.createRef();
  useSwitchToVariantImage({
    galleryImages: images,
    galleryRef,
    variant
  });

  const selectedVariantImageIndex = variant
    ? images.findIndex(image => image.url === variant.images?.[0]?.url)
    : null;

  const imageSizes = [1, 1, 1, '300', '600'];
  const [toggler, setToggler] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  let sources = [];
  for (let i = 0; i < images.length; i++) {
    sources.push(images[i].url);
  }

  return (
    <>
      <div
        {...rest}
        className={cx(rest.className, wrapper)}
        onClick={e => {
          if (e.target.clientHeight > 200) {
            setImageIndex(galleryRef?.current?.state?.currentIndex);
            setToggler(!toggler);
          }
        }}
      >
        <LoadableGallery
          aspect={'1:1'}
          images={images}
          galleryRef={galleryRef}
          thumbnailPosition={'bottom'}
          backgroundColor="#F3F3F3"
          sizes={imageSizes}
          eagerlyLoad={
            selectedVariantImageIndex ? [0, selectedVariantImageIndex] : [0]
          }
        >
          <StyledBadges badges={badges} />
        </LoadableGallery>
      </div>

      <FsLightbox
        toggler={toggler}
        type="image"
        sources={sources}
        sourceIndex={imageIndex}
      />
    </>
  );
}
